import { h, Component } from "preact";
import { Router } from "preact-router";
import Header from "./header";
import Content from "./content";
// import Footer from './footer';
import GlobalAccess from "../global-access";
import Helmet from "preact-helmet";

export default class App extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      this.state = {
        ktok: localStorage.ktok,
        headerName: "Sites",
        backlink: "",
        devices: {},
        servings: {},
        pubkeys: {},
        refresh: false,
        apiError: false,
      };
    }
  }

  componentDidMount() {
    const self = this;

    localStorage.siteNameToDisplay = "";

    if (!localStorage.ktok) {
      // if (location.href.indexOf("?") > 0) {
      // 	const creds = this.getParameterByName("creds");
      // 	if (creds && creds != "") {
      // 		Router.route("/?creds=" + creds);
      // 	} else {
      // 		Router.route("/");
      // 	}
      // } else {
      // 	Router.route("/");
      // }
    } else {
      GlobalAccess.getDevices(self);
      setInterval((t) => {
        if (self.state.refresh) GlobalAccess.getDevices(self);
      }, 3000);
    }
  }

  getParameterByName = (name) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  render() {
    let self = this;

    // let header = "";
    // let footer = "";
    // if (typeof window !== "undefined" && this.state.ktok) {
    // 	header = <Header app={this} headerName={this.state.headerName} />
    // 	footer = <Footer app={this} />
    // } else {
    // 	header = "";
    // 	footer = "";
    // }

    // let header = <Header app={this} headerName={this.state.headerName} />;

    return (
      <div id="app">
        <Helmet
          link={[
            {
              rel: "apple-touch-icon",
              sizes: "180x180",
              href: "/assets/images/apple-touch-icon.png",
            },
          ]}
        />
        {/* {header} */}
        <Content app={this} />
        {/* {footer} */}
      </div>
    );
  }
}

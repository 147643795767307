import "./style";
import App from "./components/app";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "timepicker.js/dist/timepicker.min.css";
import "timepicker.js/dist/timepicker.min.js";

import "react-datepicker/dist/react-datepicker.css";

export default App;

import Configuration from "./configuration";
const axios = require("axios").default;

export default class GlobalAccess {
  static getDevices = (self) => {
    axios
      .get(
        `${Configuration.mdashURL}/customer?access_token=${localStorage.ktok}`
      )
      .then(function (res) {
        let devices = {},
          pubkeys = res.data.pubkeys || {};
        let keys = Object.keys(pubkeys);
        let pending = keys.map(function (k) {
          let url =
            Configuration.mdashURL +
            "/api/v2/m/device?access_token=" +
            encodeURIComponent(k);
          return axios({
            method: "GET",
            url: url,
          })
            .then(function (res) {
              devices[k] = res.data;
            })
            .catch(function () {});
        });
        Promise.all(pending).then(function () {
          self.setState({
            devices: devices,
            pubkeys: pubkeys,
            apiError: false,
          });
        });
      })
      .catch(function (error) {
        console.log("API ERROR");
        console.log(error);
        self.setState({
          apiError: true,
        });
      });
  };
}
